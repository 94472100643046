import React from "react";
import { graphql } from "gatsby"
import { Helmet } from "react-helmet" 
import Header from "../components/uielements/header/header";
import Cover from "../components/project/cover";
import ProjectPreview from "../components/project/preview";
import PreviewContainer from "../components/project/previewContainer";
import Footer from "../components/footer/footer";

const ProjectsPage = ({ data: {
  allFile: { edges },
  markdownRemark: { frontmatter }
},
}) => {
  const { title, cover, path, description } = frontmatter
  const Projects = edges.map(edge => {
    return (
      <ProjectPreview
        cover={edge.node.childMarkdownRemark.frontmatter.cover}
        title={edge.node.childMarkdownRemark.frontmatter.title}
        path={edge.node.childMarkdownRemark.frontmatter.path} />
    )
  })
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}/>
      </Helmet>
      <Header />
      <Cover
        title={title}
        cover={cover}
        height={'70vh'}
      />
      <PreviewContainer>
        {Projects}
      </PreviewContainer>
      <Footer />
    </>
  )
}

export default ProjectsPage
export const pageQuery = graphql`
query  {
  allFile(filter: {relativeDirectory: {eq: "projects"}}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            category
            cover
            images
            path
            title
          }
        }
      }
    }
  },
  markdownRemark(frontmatter: { path: { eq: "/projects" } }) {
    html
    frontmatter {
      path
      title
      cover
      images
    }
  }
}
`